import React, { lazy, Suspense, useEffect, useState, useCallback } from 'react';
import { Route, useHistory } from 'react-router-dom';
import routes, { RouterUpdate } from './router';
import Spinner from 'react-bootstrap/Spinner';
import SwitchDefault from './components/hoc/SwitchDefault';
import { isMobile } from './common/util';
import { globalData } from './common/config';
import Lajax from './lib/lajax/lajax-module';

const oMeta = document.createElement('meta');
oMeta.name = 'viewport';
oMeta.id = 'viewport';
document.getElementsByTagName('head')[0].appendChild(oMeta);
if (process.env.NODE_ENV === 'production') {
  const n = new (Lajax as any)({
    url: '/v1/weblog/zyXGxgrhqIIVcdiL', // 日志发送地址，在配置文件中配置
    interval: 10000,
    autoLogAjax: false,
    maxErrorReq: 3,
    showDesc: false,
    showConsole: false,
  });
}
const App: React.FC = () => {
  const [pageIsMobile, setPageIsMobile] = useState<boolean>(false);
  useEffect(() => {
    globalData.isMobile = isMobile();
    setPageIsMobile(globalData.isMobile);
    setDefSize(globalData.isMobile);
  }, []);
  const setDefSize = useCallback(
    (isMobileType) => {
      var num = 100;
      if (window.navigator.appVersion.match(/iphone/gi)) {
        oMeta.content = 'initial-scale=0.5, maximum-scale=0.5, minimum-scale=0.5, user-scalable=no';
        num = 200;
      } else {
        oMeta.content = 'initial-scale=1,maximum-scale=1,minimum-scale=1,user-scalable=no';
      }
      if (!isMobileType) {
        oMeta.content = 'width=device-width, initial-scale=1';
        document.getElementsByTagName('body')[0].removeAttribute('style');
        document.getElementsByTagName('html')[0].removeAttribute('style');
      } else {
        const windowWidth =
          document.body.offsetWidth < window.screen.width
            ? document.body.offsetWidth
            : window.screen.width;
        const remNum = ((windowWidth / 750) * num).toFixed(0);
        document.getElementsByTagName('body')[0].style.fontSize = '0.16rem';
        document.getElementsByTagName('html')[0].style.fontSize = remNum + 'px';
      }
    },
    [pageIsMobile]
  );
  const windowOnresize = useCallback(() => {
    if (globalData.isMobile === isMobile()) return;
    globalData.isMobile = isMobile();
    if (pageIsMobile != globalData.isMobile) {
      setPageIsMobile(globalData.isMobile);
      setDefSize(globalData.isMobile);
    }
  }, [pageIsMobile]);
  useEffect(() => {
    // 监听
    window.addEventListener('resize', windowOnresize);
    // 销毁
    return () => window.removeEventListener('resize', windowOnresize);
  }, [windowOnresize]);
  return (
    <div className={pageIsMobile ? 'mobile' : 'pcweb'}>
      <Suspense fallback={<Spinner animation='grow' />}>
        <SwitchDefault>
          {routes.map((route, i) => (
            // component={route.component}
            <Route
              exact={route.exact}
              path={route.path}
              key={i}
              render={(props) => (
                <RouterUpdate>
                  <route.component />
                </RouterUpdate>
              )}
            />
          ))}
        </SwitchDefault>
      </Suspense>
    </div>
  );
};

export default App;
