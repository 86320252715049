import React from 'react';
import { Route, Switch } from 'react-router-dom';

const SwitchDefault:React.FC = ({ children }) => (
  <Switch>
    {children}
    <Route path="*">
      <div>Error</div>
    </Route>
  </Switch>
);

export default SwitchDefault;
